<template>
  <section class="wrap">
    <dl class="Hbanner white">
      <dt>移动互联网<br>解决方案提供商</dt>
      <dd>紧随国家产业供给侧改革趋势，紧抓产业互联网历史发展机遇，构建产业赋能平台，以“软件+技术”的模式，为客户提供可信赖的产品、解决方案、技术研发和系统集成等服务，帮助客户，产业升级，携手企业，合作共赢。</dd>
      <dd class="HBbtn"><a href="javascript:;" class="icon-yiwen" @click="login"> &nbsp; 了解更多</a></dd>
    </dl>
    <ul class="HTlish">
      <li><a href="#">
        <em class="icon-wulianwang"></em>
        <h6>物联网</h6>
        <p>物联网是互联网、传统电信网等信息承载体,实现互联互通的网络。</p>
        <div class="HTLtag"><span>延伸</span><span>潜能</span><span>创造</span></div>
      </a></li>
      <li><a href="#">
        <em class="icon-qukuailian"></em>
        <h6>区块链</h6>
        <p>区块链是分布式数据存储、点对点传输、共识机制、加密算法等计算机技术的新型应用模式。</p>
        <div class="HTLtag"><span>去中心化</span><span>开放性</span><span>自治性</span></div>
      </a></li>
      <li><a href="#">
        <em class="icon-yunwendang"></em>
        <h6>云计算</h6>
        <p>云计算是基于互联网的相关服务的增加、使用和交互模式，通常涉及通过互联网来提供动态易扩展且经常是虚拟化的资源。</p>
        <div class="HTLtag"><span>超大规模</span><span>虚拟化</span><span>通用性</span></div>
      </a></li>
      <li><a href="#">
        <em class="icon-shuju"></em>
        <h6>大数据</h6>
        <p>大数据指无法在一定时间范围内用常规软件工具进行捕捉、管理和处理的数据集合。</p>
        <div class="HTLtag"><span>容量</span><span>种类</span><span>价值</span></div>
      </a></li>
    </ul>
  </section>
  <dl class="Habout wrap">
    <dt>
      <div class="Lmenu"><img src="../assets/images/Lmenu.png" class="menuIcon wapShow">公司简介<img src="../assets/images/Rmenu.png" class="menuIcon"></div>
      <div class="HIabout">湖北汇充科技有限公司（简称：汇充科技）创立在湖北省武汉市光谷高新技术区。企业拥有年轻且成熟的技术团队，多年来不断创新、学习、突破、进取，深入互联网产品技术开发，透析各行业者切实需求，利用“软件+技术+服务”优势为客户提供稳定、可靠、可持续发展的一站式解决方案。同时，团队多年来致力于研磨打造生活服务平台，以技术驱动发展，让科技服务生活，用服务实现价值。</div>
      <div class="moreBtn"><a href="javascript:;" class="icon-yiwen"> &nbsp; 了解更多</a></div>
    </dt>
    <dd><img src="../assets/images/about.jpg" alt=""></dd>
  </dl>
  <section class="bg_solution minWrap">
    <article class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">解决方案<img src="../assets/images/Rmenu.png" class="menuIcon"></article>
    <ul class="solution wrap">
      <li><a href="#">
        <div><img src="../assets/images/solution1.png" alt=""></div>
        <h6>企业级应用</h6>
        <p>根据行业发展趋势，结合企业自身特征，梳理业务流程，以产业互联网的模式构建S2B2C商业逻辑，研发行业SaaS平台、ERP管理、IT运维管理平台、电子商城等平台级应用，为企业减员增效，提高收益。</p>
      </a></li>
      <li><a href="#">
        <div><img src="../assets/images/solution2.png" alt=""></div>
        <h6>移动互联网</h6>
        <p>结合位置、社交、移动性等移动互联网特性，研发手机客户端(IOS和Android) ，提供微信、支付宝、钉钉等第三方平台的小程序研发服务。</p>
      </a></li>
      <li><a href="#">
        <div><img src="../assets/images/solution3.png" alt=""></div>
        <h6>物联网</h6>
        <p>随着5G网络技术的落地和推广，物联网技术为企业经营提供更多价值服务，为企业提供互联技术研发服务,支持各种协议的硬件设备对接和数据采集，并结合业务流程对数据进行分析和预测。</p>
      </a></li>
      <li><a href="#">
        <div><img src="../assets/images/solution4.png" alt=""></div>
        <h6>区块链</h6>
        <p>有雄厚的区块链技术研发团队，对区块链的技术有深入研究，在传统游戏、供应链、支付、大数据、区块链领域积累了丰富的经验。</p>
      </a></li>
    </ul>
  </section>
  <section class="bg_cooperate minWrap">
    <article class="wrap">
      <div class="Lmenu Cmenu"><img src="../assets/images/Lmenu.png" class="menuIcon wapShow">合作案例<img src="../assets/images/Rmenu.png" class="menuIcon"></div>
      <dl class="cooperate">
        <dt>
          <a href="javascript:;">
            <div class="cooperatePic"><img src="../assets/images/laugh.png" alt=""></div>
            <h6>进口汽车供应链SaaS平台</h6>
            <div>客户:全国进口汽车的海运、报关、仓储、开证等供应链上的各类传统企业，<br>
              模式:以SaaS的模式打造S2B2C商业模式，打通供应链....</div>
            <p>查看更多》</p>
          </a>
          <a href="javascript:;">
            <div class="cooperatePic"><img src="../assets/images/laugh.png" alt=""></div>
            <h6>共享艺术馆</h6>
            <div>客户:所有艺术品爱好者<br>
              模式:融合区块链技术，搭建艺术品发布平台，实现线上艺术品拍卖和租赁.....</div>
            <p>查看更多》</p>
          </a>
        </dt>
        <dd><img src="../assets/images/cooperate.png" alt=""></dd>
      </dl>
      <div class="menu"><img src="../assets/images/Lmenu.png" class="menuIcon">联系我们<img src="../assets/images/Rmenu.png" class="menuIcon"></div>
      <dl class="Hcontact">
        <dt class="allmap" id="allmap"></dt>
        <dd>
          <p><img src="../assets/images/logo2.png" alt="logo"></p>
          <div><em class="icon-kefu"></em>联系电话：13487621881<br><em class="icon-youxiang"></em>联系邮箱：hctechmail@163.com<br><em class="icon-weizhi"></em>地址：武汉市关山大道光谷时代广场b座</div>
        </dd>
      </dl>
    </article>
  </section>
</template>

<script>
export default {
  name: "Home",
  data () {
    return {
    }
  },
  components: {
  },
  methods: {
    login(){
      console.log("登录")
      this.$store.dispatch("userLogin", true);
      localStorage.setItem("Flag", "isLogin");
    }
  },
  mounted(){
    this.$emit('getBgClass', "Hbg");
    var map = new BMap.Map("allmap");
    var point = new BMap.Point(114.420016,30.507505);
    var top_left_control = new BMap.ScaleControl({anchor: BMAP_ANCHOR_TOP_LEFT});
    var top_left_navigation = new BMap.NavigationControl();
    map.addControl(top_left_control);
    map.addControl(top_left_navigation);
    var marker = new BMap.Marker(point);
    map.addOverlay(marker);
    map.enableScrollWheelZoom(true);
    map.centerAndZoom(point, 13);
    var opts = {
      width : 200,     // 信息窗口宽度
      height: 72,     // 信息窗口高度
      title : "湖北汇充科技有限公司" , // 信息窗口标题
      enableMessage:true,//设置允许信息窗发送短息
      message:"亲耐滴，晚上一起吃个饭吧？戳下面的链接看下地址喔~"
    }
    var infoWindow = new BMap.InfoWindow("地址：武汉市东湖高新区关山大道111号光谷时代广场-B座1017", opts);  // 创建信息窗口对象 
    marker.addEventListener("click", function(){          
      map.openInfoWindow(infoWindow,point); //开启信息窗口
    });
 }
};

</script>
