<template>
  <section :class="BgClassId">
    <header class="minWrap">
      <section class="wrap" :class="{'cur':ISwapNav}" id="header">
        <dl class="wapnav">
          <dt><router-link to="./"><img src="./assets/images/logo2.png" alt="logo"></router-link></dt>
          <dd id="navMenu" @click="navMenu">
            <span class="sp1"></span>
            <span class="sp2"></span>
            <span class="sp3"></span>
          </dd>
        </dl>
        <article class="navBg"></article>
        <dl class="nav" :class="{'white':whiteClass}">
          <dt><router-link to="./"><img src="./assets/images/logo2.png" alt="logo" class="pcShow"><img src="./assets/images/logo.png" alt="logo" class="wapShow"></router-link></dt>
          <dd><router-link to="./">网站首页</router-link></dd>
          <dd><router-link to="./about">公司简介</router-link></dd>
          <dd><a href="#">解决方案</a></dd>
          <dd><router-link to="./case">合作案例</router-link></dd>
          <dd><router-link to="./contact">联系我们</router-link></dd>
        </dl>
      </section>
    </header>
    <router-view @getBgClass="BgClass" />
    <section class="bg_foot white minWrap">
      <dl class="wrap footer">
        <dt>
          <router-link to="./">官网首页</router-link>　<a href="javascript:;">解决方案</a>　<router-link to="./case">合作案例</router-link>　<router-link to="./about">公司简介</router-link><br><router-link to="./contact">联系我们</router-link>　<a href="javascript:;">发展历程</a>　<a href="javascript:;">企业文化</a>　<a href="javascript:;">办公环境</a>
        </dt>
        <dd class="Fqrcode"><img src="./assets/images/qrcode.png" alt=""></dd>
        <dd class="FContact">
          <div><em class="icon-kefu"></em><span>联系电话：</span><b>13487621881</b></div>
          <div class="FCmail"><em class="icon-youxiang"></em>联系邮箱：hctechmail@163.com</div>
          <div><em class="icon-weizhi"></em>地址：武汉市关山大道光谷时代广场b座</div>
        </dd>
      </dl>
      <article class="wrap footBot">Copyright 汇充科技 <a href="http://beian.miit.gov.cn/" target="_blank">鄂ICP备2021013162号-1</a><br>版权所有 © 湖北汇充科技有限公司</article>
    </section>
  </section>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      BgClassId: '',           // 页面背景
      whiteClass: false,       // 导航白色
      ISwapNav: false,         // wap端点击显示导航
    };
  },
  watch:{
    $route: {
      handler:function(){
        this.ISwapNav = false
      },
      // 深度观察监听
      deep: true
    }
  },
  methods:{
    navMenu(){
      this.ISwapNav = !this.ISwapNav;
    },
    BgClass (val) {
      if(val=="Hbg"){
        this.whiteClass = true;
      }else{
        this.whiteClass = false;
      }
      this.BgClassId = val;
    }
  },
  mounted(){
    console.log(this.$route.query)
  }
}
</script>

<style lang="scss">

</style>
