import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: "汇充科技",isLogin: false },
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    meta: { title: "关于我们",isLogin: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/case",
    name: "Case",
    meta: { title: "案例",isLogin: false },
    component: () =>
      import(/* webpackChunkName: "case" */ "../views/Case.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { title: "联系我们",isLogin: false },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    // path: "/404",
    path: '/:pathMatch(.*)',
    name: "404",
    meta: { title: "404" },
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  base: "/",
  routes
});

export default router;
