import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import "./assets/css/style.scss"
import util from './assets/js/util'


const Vue = createApp(App)

Vue.config.globalProperties.$util = util

router.beforeEach((to,from,next) => {
  if(to.meta.title){
    document.title = to.meta.title + '-湖北汇充科技有限公司'
  }
  let getFlag = localStorage.getItem("Flag");
  //如果登录标志存在且为isLogin，即用户已登录
  if(getFlag === "isLogin"){
    store.state.isLogin = true
    next()
    //如果已登录，还想想进入登录注册界面，则定向回首页
    if (!to.meta.isLogin) {
      console.log("请先退出登录")
      next({
        path: '/'
      })
    }
  //如果登录标志不存在，即未登录
  }else{
    //用户想进入需要登录的页面，则定向回登录界面
    if(to.meta.isLogin){
      next({
        path: '/login',
      })
      console.log("请先登录")
    //用户进入无需登录的界面，则跳转继续
    }else{
      next()
    }
  }
  
  document.documentElement.scrollTop = 0
  // next()
})
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0];
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
  if (htmlWidth >= 750) {
    htmlWidth = 750;
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320;
  }
  htmlDom.style.fontSize = `${htmlWidth / 10}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();

Vue.use(store).use(router).mount('#app')
