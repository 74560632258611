import axios from 'axios'
import { Toast } from 'vant';
import 'vant/lib/toast/style';

export default {
  globalData:{
    serverUrl: "/api/recharge/",  // 服务地址
    uniacid: 106,
    showLoadingCount: 0,          // 显示loading次数
  },
  login (queryToken) {            //登录
    if(queryToken){
      localStorage.setItem("token",queryToken);
      console.log("保存token")
    }else{
      if(!localStorage.getItem('token')){
          window.location.href="https://cz.kuhou.net/recharge?target_url=https%3A%2F%2Fcz.kuhou.net%2Fh5%2F%23"
      }
    }

  },
  request(option) {
    var that = this;
    let url = that.globalData.serverUrl + option.url
    let data = option.data ? option.data : {}
    data.uniacid = that.globalData.uniacid
    let IStoken = (option.IStoken===false) ? false : true
    if(IStoken){
      data.token = data.token || localStorage.getItem('token')
    }
    let ISloading = (option.ISloading===false) ? false : true
    if(ISloading){
      that.showFullScreenLoading();
    }
    option.header = {'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'}

    axios({
      method: "post",
      url: url,
      data: data
    }).then(function (res) {
      that.tryHideFullScreenLoading();
      option.success(res);
    })
    .catch(function (error) {
      that.tryHideFullScreenLoading();
      option.catch(error);
    });
  },
  // =============  防止ajax过多时show多次 开始  ================ //
  startLoading () {
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true
    })
  },
  endLoading () {
    Toast.clear();
  },
  showFullScreenLoading () {
    var that = this;
    if (that.globalData.showLoadingCount === 0) {
      that.startLoading();
    }
    that.globalData.showLoadingCount++;
  },
  tryHideFullScreenLoading () {
    var that = this;
    if (that.globalData.showLoadingCount <= 0) return;
    that.globalData.showLoadingCount--;
    if (that.globalData.showLoadingCount === 0) {
      that.endLoading();
    }
  },
  // =============  防止ajax过多时show多次 结束  ================ //
}
